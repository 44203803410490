import React,{ useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const invitationToConnectArray = { "fr": "Connectez-vous pour accéder à cette ressource.", "en": "Log in to access this resource.", "es": "Inicie sesión para acceder a este recurso.", "it": "Accedi per accedere a questa risorsa." };
const lang = localStorage.getItem('DENTAL_APP_LANG');
const invitationToConnect = lang ? (invitationToConnectArray[lang] ? invitationToConnectArray[lang] : invitationToConnectArray["fr"]) : invitationToConnectArray["fr"];

var checkImage = function(url, setImageExist) {
    if(!document) return;
    if(!document.createElement) return;
    let s = document.createElement("IMG");
    s.src = url
    s.onerror = function(){
        setImageExist(false);
    }
    s.onload = function(){
        setImageExist(true);
    }
}

export default function CategoryDetail({ items, title }) {
    //console.log({ items });
    return (<>
        {
            items.map((i) => {
                return <CategoryVignette key={i.name} item={i}></CategoryVignette>
            })
        }
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="vignette-link"></div>
        <div aria-hidden="true" className="title-vignette-container">{title}</div>

    </>)
}

function CategoryVignette({ item }) {

    if(item.hide === true || item.hide === "true") return null;

    if ((item.loggedin === true || item.loggedin === "true") && !window?.DENTAL_APP_USER?.id) {
        return (<div className="vignette-link">
            <a href="https://app.lyraetk.com/sign-in">
                <span className="title">{item.title}
                    <div style={{"textTransform":"initial","marginTop":"10px","padding":"5px","backgroundColor":"#B91475","borderRadius":"5px","lineHeight":"1.3","fontSize":"14px"}}>
                        {invitationToConnect}
                    </div>
                </span>
            </a>
        </div>)
    }

    let [imageExist, setImageExist] = useState(false);

    const thumbnailHref = item.thumbnailHref;
    useEffect(() => {
        checkImage(thumbnailHref, setImageExist)
    }, [thumbnailHref]);

    return (<div className="vignette-link">
        <Link style={ imageExist ? { backgroundImage: `url(${item.thumbnailHref})` } : null } to={item.href}>
            <span className="title">{item.title}</span>
        </Link>
    </div>)

}